<template>
  <div class="flex-grow-1 w-100 d-flex align-items-center">
    <div class="bg-composed-wrapper--content py-5">
      <div class="container">
        <div class="row">
          <!-- <div
            class="
              col-lg-8
              pl-0
              flex-column
              align-items-start
              justify-content-center
              text-center text-lg-left
              pr-5
            "
            style="background: #A3997E;"
          >

            <img
              src="@/assets/img/login-elearn.png"
              class="img-fluid d-none d-lg-flex"
            />
          </div> -->

          <div
            class="
              col-12
              offset-1
              col-lg-12
              offset-lg-0
              pr-0
              d-flex
              flex-column
              justify-content-center
            "
          >
            <b-card class="text-black mt-3 my-4" style="box-shadow: none;">
              <h1
                class="display-3 mb-3 text-primary font-weight-bold text-center"
              >
                สมัครสมาชิก
              </h1>

              <!-- <div class="bg-secondary rounded p-4 my-4">
                <div class="text-black-50 text-center mb-3">
                  Sign in with
                </div>
                
              </div> -->

              <div>
                <form>
                  <div class="form-group">
                    <label for="tel">ชื่อ-นามสกุล</label>
                    <input type="text" class="form-control" name="tel" />
                  </div>

                  <div class="form-group">
                    <label for="tel">ชื่อผู้ใช้</label>
                    <input type="text" class="form-control" name="tel" />
                  </div>

                  <div class="form-group">
                    <label for="tel">รหัสประจำตัวประชาชน</label>
                    <input type="text" class="form-control" name="tel" />
                  </div>

                  <div class="form-group">
                    <label for="tel">E-mail</label>
                    <input
                      type="text"
                      class="form-control"
                      name="tel"
                      v-model="form.tel"
                    />
                  </div>

                  <div class="form-group">
                    <label for="tel">รหัสผ่าน</label>
                    <input type="password" class="form-control" name="tel" />
                  </div>
                  <div class="row form-group" style="margin-left: 20px;">
                    <div class="col-0.5">
                      <input type="checkbox" />
                    </div>
                    <div class="col">
                      <p style="font-size: 0.8rem;">
                        <u>ยอมรับนโยบายความเป็นความเป็นส่วนตัว</u>
                      </p>
                    </div>
                  </div>

                  <div
                    class="row form-group"
                    style="margin-left: 20px; margin-top: -20px;"
                  >
                    <div class="col-0.5">
                      <input type="checkbox" />
                    </div>
                    <div class="col">
                      <p style="font-size: 0.8rem;">
                        <u>ยอมรับข้อกำหนดและเงื่อนไขในการใช้งาน</u>
                      </p>
                    </div>
                  </div>

                  <button
                    class="btn btn-lg btn-primary btn-block form-group"
                    :disabled="checkingSignIn || otpTimeout > 0"
                    @click.prevent="signIn"
                  >
                    <span style="color: #fff;">
                      <b>สมัครสมาชิก</b>
                    </span>
                  </button>

                  <div class="row form-group">
                    <!-- <div class="col">
                      ----------
                    </div> -->
                    <div class="col text-center">
                      หรือเป็นสมาชิกแล้ว?
                    </div>
                    <!-- <div class="col">
                      ----------
                    </div> -->
                  </div>

                  <button
                    class="btn btn-lg btn-whitebtn btn-block form-group"
                    style="border: 1px solid #000;"
                    @click.prevent="signin"
                  >
                    <span style="">
                      <b>เข้าสู่ระบบ</b>
                    </span>
                  </button>
                </form>
              </div>
            </b-card>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// import LogoSvg from "../../assets/img/logo.svg";
import { Auth } from "../../models";

export default {
  components: {
    // LogoSvg,
  },

  data() {
    return {
      showModalDoc: false,
      showModalOTP: false,
      checkingSignIn: false,
      checkingOTP: false,
      otpTimeout: 0,
      optInterval: null,
      form: {
        tel: "",
        authToken: "",
        token: "",
        user_profiles: null,
        otp_code: "",
      },
    };
  },

  watch: {
    otpTimeout: "onOtpTimeoutChanged",
  },

  methods: {
    signin() {
      location.href = "/sign-in";
    },
    async signIn() {
      this.checkingSignIn = true;

      let { tel } = this.form;

      tel = tel.replace(/-/g, "");

      try {
        const { user_profiles, token: authToken } = await Auth.api().signIn(
          tel
        );

        this.$set(this.form, "authToken", authToken);
        this.$set(this.form, "user_profiles", user_profiles);

        const token = await Auth.api().getOTP(tel);

        this.$set(this.form, "token", token);

        this.setOtpTimeout();

        this.showModalOTP = true;
      } catch (message) {
        this.$toast.error(message);
      } finally {
        this.checkingSignIn = false;
      }
    },

    setOtpTimeout() {
      this.otpTimeout = 30;
    },

    onOtpTimeoutChanged(v) {
      if (v > 0 && !this.optInterval) {
        this.optInterval = setInterval(() => {
          this.otpTimeout -= 1;
        }, 1000);
      } else {
        if (v === 0) {
          clearInterval(this.optInterval);
          this.optInterval = null;
        }
      }
    },

    async validateOTP() {
      this.checkingOTP = true;
      try {
        if (!this.form.otp_code.length) {
          throw "กรุณากรอกรหัส OTP";
        }

        const valid = await Auth.api().validateOTP(
          this.form.token,
          this.form.otp_code
        );

        if (valid) {
          Auth.setAuthToken(this.form.authToken);
          Auth.setAuthId(this.form.user_profiles.id);

          this.showModalOTP = false;

          this.$toast.success(
            "เข้าสู่ระบบสำเร็จ ระบบจะเข้าสู้หน้าหลักในอีกสักครู่"
          );

          setTimeout(() => {
            location.href =
              decodeURIComponent(this.$route.query.redirectUrl) || "/";
          }, 1000);
        }
      } catch (message) {
        console.log(message);
        this.$toast.error(message);
      } finally {
        this.checkingOTP = false;
      }
    },
  },
};
</script>
